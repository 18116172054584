<template>
  <div class="main">
    <div class="opt">
      <div class="title">{{ $fanyi('订单状态概况') }}</div>
      <div class="pageJumpListContainer">
        <div class="pageJumpContainer" @click="
          $fun.toPage('/user/order?status=temporary')
        update()
          ">
          <div class="icon temporary">
            <img src="../../../../assets/user-img/order_1.svg" alt="" />
          </div>
          <div class="right">
            <div class="text">{{ $fanyi('临时保存的订单') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.order_tamp" :endVal="newCountNumInfo.order_tamp" :duration="3000" />
            </div>
          </div>
        </div>
        <div class="pageJumpContainer" @click="
          $fun.toPage('/user/order?status=obligation'),
          update()
          ">
          <div class="icon pending">
            <img src="../../../../assets/user-img/order_2.svg" alt="" />
          </div>
          <div class="right">
            <div class="text">{{ $fanyi('待处理订单') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.order_un_delivery" :endVal="newCountNumInfo.order_un_delivery"
                :duration="3000" />
            </div>
          </div>
        </div>
        <div class="pageJumpContainer" @click="
          $fun.toPage('/user/order?status=received'),
          update()
          ">
          <div class="icon RECEPCIONPENDIENTE">
            <img src="../../../../assets/user-img/order_3.svg" alt="" />
          </div>
          <!--          待收货订单-->
          <div class="right">
            <div class="text">{{ $fanyi('等待工厂发货的订单') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.order_purchase_uncomplete"
                :endVal="newCountNumInfo.order_purchase_uncomplete" :duration="3000" />
            </div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click="
          $fun.toPage('/user/order?status=inspection'),

          update()
          ">
          <div class="icon CONTROLDECALIDAD">
            <img src="../../../../assets/user-img/order_4.svg" alt="" />
          </div>
          <!--          检品中订单-->
          <div class="right">
            <div class="text">{{ $fanyi('质量控制和处理') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.order_inspecting" :endVal="newCountNumInfo.order_inspecting"
                :duration="3000" />
            </div>
          </div>
        </div>
      </div>
      <div class="pageJumpListContainer" @click="
        $fun.toPage('/user/order?status=warehouse'),

        update()
        ">
        <div class="pageJumpContainer" style="margin: 0 20px 20px 0;">
          <div class="icon WAREHOUSE">
            <img src="../../../../assets/user-img/order_5.svg" alt="" />
          </div>
          <!--          在RAKUMART仓库中订单-->
          <div class="right">
            <div class="text">{{ $fanyi('货物到达RAKUMART中国仓库') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.order_up_complete" :endVal="newCountNumInfo.order_up_complete"
                :duration="3000" />
            </div>
          </div>
        </div>

        <div class="pageJumpContainer" @click.stop="
          $fun.toPage('/user/wuliuStatus?status=internationalFreightToBeQuotedDeliverySheet'),

          update()
          ">
          <div class="icon FLETEPENDIENTECOTIZACION">
            <img src="../../../../assets/user-img/order_6.svg" alt="" />
          </div>
          <!--          国际运费待报价配送单-->
          <div class="right">
            <div class="text">{{ $fanyi('国际运费报价') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_wait_offer" :endVal="newCountNumInfo.porder_wait_offer"
                :duration="3000" />
            </div>
          </div>
        </div>

        <div class="pageJumpContainer" @click.stop="
          $fun.toPage('/user/wuliuStatus?status=Tobepaid')">
          <div class="icon ENVIOPENDIENTEDEPAGO">
            <img src="../../../../assets/user-img/order_7.svg" alt="" />
          </div>
          <!--          国际运费待支付配送单-->
          <div class="right">
            <div class="text">{{ $fanyi('国际运费待支付') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_wait_pay" :endVal="newCountNumInfo.porder_wait_pay"
                :duration="3000" />
            </div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click.stop="
          $fun.toPage('/user/wuliuStatus?status=orderForDelivery'),
          update()
          ">
          <div class="icon ESPERADERECOGIDA">
            <img src="../../../../assets/user-img/order_8.svg" alt="" />
          </div>
          <!--          待发货配送单-->
          <div class="right">
            <div class="text">{{ $fanyi('已入仓待装箱发货') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_pay_un_delivery" :endVal="newCountNumInfo.porder_pay_un_delivery"
                :duration="3000" />
            </div>
          </div>
        </div>
      </div>
      <div class="pageJumpListContainer" @click="
        $fun.toPage('/user/wuliuStatus?status=deliveryInTransit'),
        update()
        ">
        <div class="pageJumpContainer">
          <div class="icon ENTRANSITOHACIAESPAÑA">
            <img src="../../../../assets/user-img/order_9.svg" alt="" />
          </div>
          <!--          运输中配送单-->
          <div class="right">
            <div class="text">{{ $fanyi('中国发往美国的订单') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_in_sea" :endVal="newCountNumInfo.porder_in_sea"
                :duration="3000" />
            </div>
          </div>
        </div>
        <!--        <div class="pageJumpContainer" @click="$router.push({ name: 'wuliuStatus',query:{status:'deliveryFormToRAKUMARTTransportCentre'}});update()">-->
        <!--          <div class="icon ALMACENESPAÑA"><img src="../../../../assets/user-img/order_10.svg" alt=""></div>-->
        <!--&lt;!&ndash;          到达RAKUMART运输中心配送单&ndash;&gt;-->
        <!--          <div class="right">-->
        <!--            <div class="text">{{$fanyi("货物到达RAKUMART美国仓库")}}</div>-->
        <!--            <div class="number"><countTo :startVal='countNumInfo.porder_cleared' :endVal='newCountNumInfo.porder_cleared' :duration='3000' /></div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="pageJumpContainer" @click.stop="
          $fun.toPage('/user/wuliuStatus?status=Usdeliveryunderway'), update()
          ">
          <div class="icon ENVIONACIONALENCURSO">
            <img src="../../../../assets/user-img/order_10.svg" alt="" />
          </div>
          <!--          西班牙派送配送单-->
          <div class="right">
            <div class="text">{{ $fanyi('美国派送中') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_foreign_express" :endVal="newCountNumInfo.porder_foreign_express"
                :duration="3000" />
            </div>
          </div>
        </div>
        <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click.stop="
          $fun.toPage('/user/wuliuStatus?status=Signedandreceived'), update()">
          <div class="icon ENTREGADO">
            <img src="../../../../assets/user-img/order_11.svg" alt="" />
          </div>
          <!--已签收配送单 -->
          <div class="right">
            <div class="text">{{ $fanyi('已收货') }}</div>
            <div class="number">
              <countTo :startVal="countNumInfo.porder_receive" :endVal="newCountNumInfo.porder_receive"
                :duration="3000" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countTo from 'vue-count-to'
export default {
  components: {
    countTo,
  },
  data() {
    return {
      shopLoading: true,
      countNumInfo: {
        order_tamp: 0, //临时订单
        order_un_delivery: 0, //待处理订单(未完全发出)
        order_purchase_uncomplete: 0, //购买中
        order_up_complete: 0, //货物上架完成 (未完全发出)
        order_inspecting: 0, //检品中
        porder_wait_offer: 0, //配送单待报价
        porder_wait_pay: 0, //配送单待支付
        porder_pay_un_delivery: 0, //已支付待发货
        porder_in_sea: 0, //国际运输中
        porder_cleared: 0, //已通过
        porder_foreign_express: 0, //外国运输中
        porder_receive: 0, //已签收
      },
      newCountNumInfo: {
        order_tamp: 0, //临时订单
        order_un_delivery: 0, //待处理订单(未完全发出)
        order_purchase_uncomplete: 0, //购买中
        order_up_complete: 0, //货物上架完成 (未完全发出)
        order_inspecting: 0, //检品中
        porder_wait_offer: 0, //配送单待报价
        porder_wait_pay: 0, //配送单待支付
        porder_pay_un_delivery: 0, //已支付待发货
        porder_in_sea: 0, //国际运输中
        porder_cleared: 0, //已通过
        porder_foreign_express: 0, //外国运输中
        porder_receive: 0, //已签收
      },
    }
  },

  created() {
    this.getContentCountNum()
  },
  methods: {
    // 获取个人中心看板统计底部统计数据
    getContentCountNum() {
      this.$api
        .contentCountNum()
        .then((res) => {
          this.newCountNumInfo = res.data
        })
        .catch((err) => { })
    },
    update() {
      this.$emit('signStatusVerdict', 1)
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  .opt {
    width: 1052px;
    margin-bottom: 20px;

    .title {
      font-size: 16px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #222222;
      line-height: 24px;
      margin-bottom: 21px;
    }

    .pageJumpListContainer {
      display: flex;
      align-items: center;

      .pageJumpContainer {
        width: 250px;
        height: 124px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e2e2e2;
        background: #ffffff;
        border: 1px solid #e3e3e3;
        margin: 0 20px 20px 0;
        padding: 30px 0 23px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 64px;
          height: 64px;
          background: #fff2e8;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .temporary {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .pending {
          padding-right: 3px;

          img {
            width: 36px;
            height: 36px;
          }
        }

        .RECEPCIONPENDIENTE {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .WAREHOUSE {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .CONTROLDECALIDAD {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .FLETEPENDIENTECOTIZACION {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ENVIOPENDIENTEDEPAGO {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ESPERADERECOGIDA {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ENTRANSITOHACIAESPAÑA {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ALMACENESPAÑA {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ENVIONACIONALENCURSO {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .ENTREGADO {
          img {
            width: 36px;
            height: 36px;
          }
        }

        .right {
          .number {
            color: #2f2f2f;
            font-weight: 600;
            font-size: 24px;
          }

          .text {
            color: #222222;
            font-size: 14px;
            margin-bottom: 7px;
          }
        }
      }
    }
  }
}
</style>
